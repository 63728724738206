// 3rd Party
@import '~jquery-ui/themes/base/all.css';
@import "~bootstrap/scss/bootstrap";
@import "../../shared/fontawesome-pro-5.15.3-web/css/all.min.css";

// Mixins
@import "mixins/svg-icons";

// Base
@import "base/variables";
@import "base/fonts";
@import "base/icons";

// Page elements
@import "page_elements/breadcrumb";
@import "page_elements/content";
@import "page_elements/header";
@import "page_elements/layout";
@import "page_elements/navigation";
@import "page_elements/search";
@import "page_elements/two-column";

// Generic elements
@import "generic_elements/alert";
@import "generic_elements/button";
@import "generic_elements/form";
@import "generic_elements/table";
@import "generic_elements/tabs";
@import "generic_elements/modal";
@import "generic_elements/toolbar";
@import "generic_elements/accordion";

// Specific pages
@import "specific_pages/styleguide";
@import "specific_pages/login";
@import "specific_pages/access-denied";
@import "specific_pages/boards";
@import "specific_pages/product";
@import "specific_pages/dashboard";
@import "specific_pages/profile";
@import "specific_pages/batch";
@import "specific_pages/controlpanel-overview";
@import "specific_pages/controlpanel-boards";
@import "specific_pages/controlpanel-models";
@import "specific_pages/controlpanel-channelmanager";
@import "specific_pages/controlpanel-bulk-move-products";
@import "specific_pages/controlpanel-importruns";
@import "specific_pages/reports";
@import "specific_pages/filter";
