@import "../fonts/averta-std/font-face.css";

html, body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: 18px;
  color: $font-color;
}

h1 {
  font-size: 2rem;
  font-weight: $font-weight-bold;
  margin-bottom: 15px;
}

h2 {
  font-size: 1.571rem;
  font-weight: $font-weight-semibold;
}

h3, h4, h5, h6 {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
}

a {
  color: $lightblue;
  text-decoration: none;
}

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300;
}

.far {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 400;
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
}

@include media-breakpoint-down(sm) {
    h1 {
        margin-bottom: 8px;
    }
}