body.controlpanel.bulk_move_products {
    .ms-container {
        width: auto;

        .select-header {
            min-height: 60px;
        }
    }

    @include media-breakpoint-down(lg) {
        .ms-container {
            .ms-selectable {
                float: none;
                width: 100%;
            }

            .ms-selection {
                float: none;
                width: 100%;
                margin-top: 15px;
            }

            .select-header {
                min-height: auto;
                margin-bottom: 15px;
            }
        }
    }
}