@mixin icon($icon-name, $width: 3.5rem, $height: 3.5rem, $background-size: false, $property: background-image) {
    #{$property}: url('/public/themes/mars/img/icons/#{$icon-name}.svg');
    width: $width;
    height: $height;
    background-repeat: no-repeat;

    @if $background-size != false {
        background-size: #{$background-size};
    }

    @content;
}

@mixin icon-before($icon-name, $property: background-image) {
    #{$property}: url('/public/themes/mars/img/icons/#{$icon-name}.svg');
    background-repeat: no-repeat;
    background-position: 10px 7.5px;

    @content;
}
