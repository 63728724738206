.content-container {
    > .alert {
        margin: 40px 40px -20px;

        @include media-breakpoint-down(xl) {
            margin: 20px 20px -10px;
        }
    }
}

.alert {
    border: none;
    position: relative;
    color: $font-color;
    font-size: 0.857rem;
    padding: 0.571rem 1rem 0.571rem 35px;
    border-radius: 0;

    > div {
        display: inline-block;

        p,
        ul {
            margin-bottom: 0.357rem;
        }

        &.flex-wrapper {
            width: calc(100% - 29px);
        }

        .title {
            font-size: $font-size-base;
        }
    }

    &:after {
        position: absolute;
        left: 0;
        top: 0;
        width: 6px;
        display: inline-block;
        height: 100%;
        content: ' ';

    }

    &.alert-success {
        background-color: #DDFFE3;
        @include icon-before('success-icon');

        &:after {
            background-color: $success;
        }

        .btn.btn-secondary {
            border-color: $success;
            color: $success;
        }
    }

    &.alert-info {
        @include icon-before('info-icon');

        &:after {
            background-color: $info;
        }
    }

    &.alert-error {
        background-color: #FFEEEE;
        @include icon-before('error-icon');

        &:after {
            background-color: $danger;
        }

        .btn.btn-secondary {
            border-color: $danger;
            color: $danger;
        }
    }

    &.alert-warning {
        background-color: #FFF8E0;
        @include icon-before('warning-icon');

        &:after {
            background-color: $warning;
        }

        .btn.btn-secondary {
            border-color: $warning;
            color: $danger;
        }
    }
}
