.btn {
    line-height: 16px;
    border-radius: 0;
    padding: 10px 8px;
    border-width: 1px;
    display: flex;
    align-items: center;
    gap: 3px;

    &:before, &:after {
        font-family: $font-font-awesome;
    }

    &.no-text {
        &:before {
            margin: 0;
        }
    }

    &.btn-sm {
        padding: 6px 6px;
    }

    &.btn-primary {
        color: $primary-button-color;
        background-color: $primary-button-background;
        border: $primary-button-border;

        &:hover,
        &.hover {
            background-color: $primary-button-background-hover;
        }

        &:active,
        &.active {
            background-color: $primary-button-background-active;
        }
    }

    &.btn-secondary {
        color: $secondary-button-color;
        background-color: $secondary-button-background;
        border: $secondary-button-border;

        &:hover,
        &.hover {
            background-color: $secondary-button-background-hover;
        }

        &:active,
        &.active {
            background-color: $secondary-button-background-active;
        }
    }

    &.btn-delete {
        color: $delete-button-color;
        background-color: $delete-button-background;
        border: $delete-button-border;

        &:before {
            content: '\f2ed';
        }

        &:hover,
        &.hover {
            background-color: $delete-button-background-hover;
        }

        &:active,
        &.active {
            background-color: $delete-button-background-active;
        }
    }

    &.btn-columns {
        &:before {
            content: '\f0db';
        }
    }

    &.btn-boards {
        &:before {
            content: '\f328';
        }
    }

    &.btn-languages {
        &:before {
            content: '\f1ab';
        }
    }

    &.btn-properties {
        &:before {
            content: '\f03a';
        }
    }

    &.btn-filters {
        &:before {
            content: '\f0b0';
        }
    }
}

.dropdown-menu {
    &.show {
        border-radius: 0;
    }
}

.uploadifive-button {
    @extend #{'.btn','.btn-primary'};
    display: inline-block !important;
    width: auto !important;
    height: auto !important;
    line-height: initial !important;

    &:hover {
        @extend #{'.btn','.btn-primary', '.hover'};
    }

    input:hover {
        cursor: pointer;
    }
}
