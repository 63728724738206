body.controlpanel.overview {
    .content-container {
        .breadcrumb-container {
            display: none;
        }

        .content {
            padding: 0;

            .header {
                background: #E1EEF4;
                padding: 35px 40px;

                h1 {
                    margin: 0;
                    font-size: 2rem;
                }

                @include media-breakpoint-down(xl) {
                    padding: 20px;
                }
            }

            .segment-blocks {
                padding: 40px;

                @include media-breakpoint-down(xl) {
                    padding: 20px;
                }
            }
        }
    }
}