body.styleguide {
    section:not(.breadcrumb-container) {
        margin-bottom: 4rem;
    }

    .base-colors {
        > div {
            display: inline-block;

            &:not(:last-of-type) {
                border-right: 1px solid #B7B7B7;
                padding-right: 3rem;
                margin-right: 3rem;
            }
        }

        .color {
            width: 74px;
            height: 74px;
            display: block;
            margin-bottom: 3rem;

            &.darkblue {
                background: $darkblue;
            }

            &.blue {
                background: $blue;
            }

            &.lightblue {
                background: $lightblue;
            }

            &.black {
                background: $black;
            }

            &.lightgrey {
                background: $lightgrey;
            }

            &.secondarygrey {
                background: $secondarygrey;
            }

            &.tertiarygrey {
                background: $tertiarygrey;
            }

            &.darkgrey {
                background: $darkgrey;
            }

            &.danger {
                background: $danger;
            }

            &.success {
                background: $success;
            }

            &.warning {
                background: $warning;
            }

            &.info {
                background: $info;
            }
        }
    }

    .icons {
        .icon {
            display: inline-block;
            margin-right: 50px;
        }
    }
}
