body.batch {
    table#batch_table {
        border: 1px solid #dddddd;

        .batch_border {
            .tr:first-child {
                border: 1px solid #dddddd;
            }
        }

        .secondarygrey {
            background-color: #f3f3f3;
            border: 1px solid #dddddd;
        }

        th, .td-container {
            padding: 15px;
        }

        th,
        .mobile-show {
            > div {
                color: $lightblue;
                font-size: 1.143rem;
                font-weight: normal;
                margin-bottom: 5px;
            }

            h2 {
                color: #000;
                font-size: 1.571rem;
                margin-bottom: 0;
            }
        }

        .mobile-show {
            border-bottom: 1px solid #dddddd;
            padding-bottom: 1rem;
            margin-bottom: 1rem;
        }

        .table_content {
            > div {
                margin-bottom: 15px;
                font-size: 1.142rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:hover {
                    cursor: pointer;
                }

                i {
                    color: $lightblue;
                    margin-left: 5px;
                }
            }
        }

        .property_template {
            border: 1px solid #dddddd;
            font-size: 1rem;
            padding: 15px;
            margin-bottom: 15px;

            > * {
                margin-bottom: 5px;
                display: block;
            }

            input {
                background: #ededed;
                border: 1px solid #a3a3a3;
                color: #a3a3a3;
            }

            i {
                cursor: pointer;
            }

            .title, .board {
                font-size: 1rem;
                color: #000000;
            }

            .board {
                color: #a3a3a3;
            }
        }

        .footer-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 15px;
        }
    }

    #property_type_containers {
        display: none;
    }

    .mobile-show {
        display: none;
    }

    @include media-breakpoint-down(xl) {
        .mobile-remove {
            display: none;
        }

        .mobile-show {
            display: block;
        }

        .table.table-bordered.batch_border {
            tr {
                display: table;
                width:100%;

                td {
                    display: table-row;
                }
            }
        }
    }
}


