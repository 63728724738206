body.filter {
    table {
        tr {
            td {
                position: relative;
            }
        }
    }

    .lvw_container {
        .edit_box,
        .fa-pencil {
            display: none;
        }

        i {
            cursor: pointer;
        }

        .value {
            display: none;
        }
    }

    .edit_box {
        display: flex;
        align-items: center;
        gap: 10px;

        .language-wrapper {
            display: flex;
            flex-direction: column;
            gap: 5px;

            .input-wrapper {
                display: inherit;
                gap: 5px;
            }
        }
    }

    .pencil-dummy {
        width: 12px;
        height: 11px;
        display: inline-block;
    }

    #filtersManagePropertiesModal {
        .propertgroupName {
            cursor: pointer;
        }
    }

    #filtersLanguagesModal {
        p.languageEntry {
            display: flex;
            gap: 10px;
        }
    }

    #filtersViewsModal {
        .filterViewEntry {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px;

            input {
                display: none;
            }

            .fa-times,
            .fa-pencil {
                cursor: pointer;
            }

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            &:hover {
                background: #ebebeb;
                cursor: pointer;
            }
        }
    }

    .modal {
        .propertgroupName {
            font-size: 1.142rem;
        }

        .propertyEntry {
            .type {
                color: #c6c6c6;
            }

            .name {
                cursor: pointer;
            }
        }
    }

    #property_type_containers {
        display: none;
    }

    #filter_container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-bottom: 20px;;

        .form-actions {
            margin-bottom: 0;
        }

        #properties_container {
            display: flex;
            gap: 20px;
            flex-wrap: wrap;

            .filter_container {
                display: flex;
                align-items: center;
                gap: 5px;
                border: $input-border;
                padding: 5px;

                .filter_box {
                    display: inherit;
                    align-items: inherit;
                    gap: inherit;
                    position: relative;

                    .filtered {
                        border: $input-border;
                        padding: 5px;
                    }

                    .filter_popout {
                        display: inherit;
                        gap: inherit;
                        position: absolute;
                        top: 40px;
                        left: -5px;
                        background: $white;
                        padding: 5px;
                        border: $input-border;
                    }
                }

                .remove_filter {
                    cursor: pointer;
                }
            }
        }
    }
}