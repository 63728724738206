#widgets {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 40px;

    .widget-column {
        .widget {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #DDDDDD;
            padding: 20px;
            margin-bottom: 40px;

            .widget-header {
                cursor: pointer;

                h2 {
                    font-size: 1.285rem;
                    margin-bottom: 20px;
                }
            }

            .widget-content {
                i {
                    font-size: 0.8rem;
                }

                table {
                    font-size: 1rem;
                    margin-bottom: 0;

                    tr:first-child {
                        border-top: 0;
                        border-color: inherit;
                    }

                    tr {
                        &.user_comments_row,
                        &.comments_row{
                            border-bottom: 1px solid #dee2e6;

                            &:first-child {
                                td {
                                    padding-top: 0;
                                }
                            }

                            td {
                                padding: 10px 0;

                                > * {
                                    padding: 0;
                                }

                                &.avatar {
                                    width: 47px;
                                    vertical-align: top;
                                    padding-right: 15px;

                                    a {
                                        padding: 0;
                                    }
                                }
                            }

                            .comment_date {
                                color: $darkgrey;
                            }

                            .comment_message {
                                padding-top: 5px;
                            }
                        }

                        &.user_comments_row:last-of-type,
                        &.comments_row:last-of-type{
                            border-bottom: 0;
                        }

                        th {
                            height: auto;
                        }

                        td {
                            padding: 10px;
                        }
                    }
                }

                .task-row {
                    &:last-of-type {
                        border: 0;

                        > td {
                            border: 0;
                        }
                    }

                    td:last-of-type {
                        vertical-align: bottom;
                        text-align: right;
                    }
                }

                .complete_task_btn {
                    display: inline-block;
                }

                .prev-next-buttons {
                    display: flex;

                    a {
                        margin-top: 1rem;
                    }

                    a:last-of-type {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(xxl) {
    #widgets {
        grid-template-columns: 1fr;
        gap: 0;
    }
}