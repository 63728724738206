@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Regular.svg) format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-RegularItalic.svg) format('svg');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Bold.svg) format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BoldItalic.svg) format('svg');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Extrabold.svg) format('svg');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-ExtraboldItalic.svg) format('svg');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Black.svg) format('svg');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-BlackItalic.svg) format('svg');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-Semibold.svg) format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'AvertaStdPe';
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.eot);
    src: url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.eot) format('embedded-opentype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.woff2) format('woff2'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.woff) format('woff'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.ttf) format('truetype'), url(/public/themes/mars/fonts/averta-std/AvertaStdPE-SemiboldItalic.svg) format('svg');
    font-weight: 600;
    font-style: italic;
}
