// Fonts
$font-size-base: 14px;
$font-size-large: 1.143rem;
$font-size-small: 0.857rem;
$font-color: $black;
$font-font-averta: 'AvertaStdPe', Arial, sans-serif;
$font-font-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-font-averta;
$font-weight-bold: 800;
$font-weight-semibold: 600;

// Base colors
$blue: #355462;
$lightblue: #048AC2;
$lightestblue: #E5F6FD;
$darkblue: #083143;
$black: #000;

$base-color: $darkblue;

// Grey colors
$lightgrey: #f5f5f5;
$secondarygrey: #e4e4e4;
$tertiarygrey: #d5d5d5;
$darkgrey: #bebebe;

// Status colors
$danger: #e33535;
$success: #00a41b;
$warning: #fdb833;
$info: $lightblue;

// colors
$red: $danger;

// Buttons
$primary-button-color: $white;
$primary-button-background: $lightblue;
$primary-button-background-hover: #0099D8;
$primary-button-background-active: #026E9B;
$primary-button-border: 1px solid $lightblue;

$secondary-button-color: $lightblue;
$secondary-button-background: $white;
$secondary-button-background-hover: #F6FAFF;
$secondary-button-background-active: #E5F6FD;
$secondary-button-border: 1px solid $lightblue;

$delete-button-color: $danger;
$delete-button-background: $white;
$delete-button-background-hover: #FFF7F7;
$delete-button-background-active: #FEE9E9;
$delete-button-border: 1px solid $danger;

// Form elements
$input-border-color: #BBBBBB;
$input-border: 1px solid $input-border-color;
$input-checked-background: $lightblue;
$input-checked-color: $white;

// Tables
$table-border-color: #E6E6E6;