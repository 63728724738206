.modal {
    .modal-header {
        padding: 10px 20px;

        h1 {
            font-size: 1.714rem;

            i {
                color: $lightblue;
            }
        }

        i.fa-times {
            font-size: 2.142rem;
            color: $lightblue;
            cursor: pointer;
        }
    }

    .modal-body,
    .modal-footer {
        padding: 10px 20px;
    }

    .modal-body {
        .loading {
            text-align: center;
        }
    }

    table {
        tr {
            &:first-child {
                border-color: $table-border-color;
                border-top: 0;

                th {
                    height: auto;
                    padding: 5px 0;
                }
            }

            td {
                padding: 5px 0;

                > a {
                    padding: 0;

                    i {
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}