body.controlpanel.models {
    #properties_buffer,
    #sharedproperties_buffer,
    .modeldimension_properties_buffer {
        .properties-header {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }

        .form-group {
            display: none;
            grid-template-columns: repeat(3, 1fr);
            gap: 10px;
        }

        .mobile-title {
            display: none;
        }

        .fa-times {
            cursor: pointer;
        }
    }
}

@include media-breakpoint-down(xl) {
    body.controlpanel.models {
        #properties_buffer {
            .mobile-title {
                display: inline-block;
            }

            .properties-header {
                display: none;
            }

            .form-group {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                border-bottom: 1px solid #dddddd;
                padding-bottom: 20px;
                align-items: start;
            }
        }
    }
}