header {
    background: $blue;
    min-height: 50px;
    display: flex;
    gap: 30px;
    flex: 0 0 100%;

    > div {
        display: flex;
        align-items: stretch;

        &:first-of-type {
            margin-left: 15px;
        }
    }

    .menu-btn-container {
        color: $white;
        align-items: center;
        min-width: 75px;

        .menu-btn {
            display: flex;
            align-items: center;
            color: $white;
            gap: 5px;
            font-size: 1.2rem;
            text-decoration: none;
            padding: 17px 0;
            border: none;

            .icon,
            &:before,
            span {
                display: flex;
            }


            .icon {
                display: inline-block;
            }

            span  {
                align-self: flex-start;
            }

            &:focus,
            &:active,
            &:visited,
            &:hover {
                color: $white;
                border: none;
            }

            &:not(.hide-menu) {
                &:before,
                &:after {
                    font-family: $font-font-awesome;
                }

                &:before {
                    text-align: center;
                    margin: 0;
                    width: 20px;
                    height: 17px;
                    font-size: 1.4rem;
                    display: inline-block;
                    vertical-align: middle;
                    content: '\f0c9';
                }

                .icon {
                    display: none;
                }
            }
        }
    }

    .logo-container {
        align-items: center;

        .logo {
            display: flex;
            color: $white;
            gap: 5px;

            .optimizers {
                font-size: 1.571rem;
                font-weight: $font-weight-semibold;
                text-transform: capitalize;
                align-self: center;
            }

            .pim {
                font-size: 1.071rem;
                text-transform: uppercase;
                background: #3A86A7;
                border-radius: 2px;
                padding: 5px;
            }
        }
    }

    .top-nav {
        margin-left: auto;

        > a,
        > div {
            text-decoration: none;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 8px;
            position: relative;
            min-width: 50px;

            i {
                font-size: 1.5rem;
            }
        }

        > a:hover, > a:active, > div:hover {
            cursor: pointer;
            background: #3A86A7;

            .dropdown-menu {
                display: block;
                z-index: 1055;
            }
        }

        .username {
            padding-left: 8px;
        }

        .dropdown:hover::after {
            position: absolute;
            right: 12px;
            bottom: 0;
            display: inline-block;
            color: $white;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $white;
            content: ' ';
        }

        .dropdown-menu {
            top: 51px;
            right: 0;
            min-width: 225px;
            display: none;
            padding: 20px;
            background: $white 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 1px solid #DCDCDC;
            border-top: 0;
            opacity: 1;
            border-radius: 0;

            .title {
                margin-bottom: 25px;
                font-size: 1.429rem;
                font-weight: $font-weight-semibold;
            }

            ul {
                margin: 0;

                li {
                    a {
                        display: flex;
                        gap: 10px;
                        font-size: 1.143rem;
                        color: $lightblue;

                        i {
                            text-align: center;
                            flex-basis: 15%;
                        }

                        span.count {
                            background: #F26428;
                            color: $white;
                            border-radius: 50%;
                            width: 18px;
                            height: 18px;
                            text-align: center;
                            font-size: 0.857rem;
                            font-weight: $font-weight-semibold;
                        }

                        &:hover {
                            color: $lightblue;

                            span:not(.count) {
                                text-decoration: underline;
                            }
                        }
                    }

                    &:not(:last-of-type) {
                        margin-bottom: 15px;
                    }
                }
            }

            &.account {
                max-height: 310px;
                overflow-y: scroll;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    header {
        .top-nav {
            > div {
                .username {
                    display: none;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    header {
        flex-wrap: wrap;
        padding: 0;
        align-items: normal;
        gap: 0;

        .logo-container {
            order: -1;
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $darkblue;
            padding: 5px;

            .logo {
                .optimizers {
                    font-size: 1.143rem;
                }

                .pim {
                    font-size: 0.714rem;
                    padding: 2.5px;
                }
            }
        }

        .top-nav {
            .dropdown:hover::after {
                border: 0;
            }

            .dropdown-menu {
                border: 0;
                width: 100%;
                height: 100%;
                background: $white;
                box-shadow: none;
                position: fixed;
                border-radius: 0;
                top: 81px;

                .title {
                    display: flex;
                    justify-content: space-between;

                    .close {
                        width: 18px;
                        height: 18px;

                        &:before {
                            font-family: $font-font-awesome;
                            content: '\f00d';
                            color: $lightblue;
                        }
                    }
                }

                ul li a i {
                    text-align: left;
                    flex-basis: 7.5%;
                }
            }
        }

        .menu-btn-container {
            .menu-btn {
                .menu-collapse {
                    background: none;
                    width: 20px;
                    height: 13px;

                    &:before {
                        font-family: $font-font-awesome;
                        content: "\f00d";
                        font-style: initial;
                        font-size: 1.571rem;
                    }
                }
            }
        }
    }
}