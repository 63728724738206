.two-column {
    display: flex;
    gap: 40px;
    align-items: flex-start;

    .left-column {
        width: 256px;
        padding: 20px;
        border: 1px solid #DDDDDD;

        h2 {
            font-size: 1.285rem;
            margin-bottom: 15px;

            i {
                color: $success;
            }
        }

        .nav {
            flex-direction: column;

            .translation {
                margin-top: 15px;
                position: relative;

                .lang_status {
                    position: absolute;
                    left: -15px;
                    top: 10px;

                    i {
                        color: $success;
                    }
                }
            }

            > .translation ~ .translation {
                margin-top: 0;
            }

            .nav-link {
                cursor: pointer;
                text-align: left;

                &:hover {
                    color: $lightblue;
                    background-color: #F2F2F2;
                }

                &.active {
                    color: $white;
                    background-color: $lightblue;
                }

                i {
                    padding-right: 10px;
                }
            }
        }
    }

    .right-column {
        flex: 1;

        .tab-content {
            padding: 0;
            margin: 0;
            border-bottom: 0;

            h2 {
                font-size: 2rem;
            }

            .tab-pane-content {
                padding: 0 15px;

                .content-columns {
                    display: flex;
                    gap: 20px;
                    margin-bottom: 20px;

                    .content-right-column {
                        > * {
                            display: block;
                            margin-top: 10px;
                        }

                        a {
                            i {
                                color: $success;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .two-column {
        flex-direction: column;

        .left-column,
        .right-column {
            width: 100%;
        }
    }
}