.icon {
    &.b2b {
        @include icon('b2b');
    }

    &.boards {
        @include icon('boards', 3.5rem, 3.5rem, contain);
    }

    &.channelmanager-profiles {
        @include icon('channelmanager-profiles');
    }

    &.comment-tags {
        @include icon('comment-tags');
    }

    &.countries {
        @include icon('countries');
    }

    &.dashboard {
        @include icon('dashboard');
    }

    &.downloads {
        @include icon('downloads', 4rem);
    }

    &.filter {
        @include icon('filter');
    }

    &.importruns {
        @include icon('importruns');
    }

    &.languages {
        @include icon('languages');
    }

    &.models {
        @include icon('models');
    }

    &.products {
        @include icon('products');
    }

    &.properties {
        @include icon('properties');
    }

    &.property-groups {
        @include icon('property-groups', 3.5rem, 3.5rem, contain);
    }

    &.reports {
        @include icon('reports');
    }

    &.settings {
        @include icon('settings');
    }

    &.tasks {
        @include icon('tasks', 3.5rem, 3.5rem, contain);
    }

    &.user-groups {
        @include icon('user-groups', 3.5rem, 3.5rem, contain);
    }

    &.users {
        @include icon('users', 3.5rem, 3.5rem, contain);
    }

    &.userrights {
        @include icon('userrights', 3.5rem, 3.5rem, contain);
    }

    &.workflows {
        @include icon('workflows');
    }

    &.menu-collapse {
        @include icon('menu-collapse', 23px, 17px);
    }

    &.dolly {
        @include icon('dolly');
    }

    &.upload {
        @include icon('upload');
    }
}