.search-container {
    display: flex;
    background: $lightgrey;
    justify-content: space-between;
    padding: 5px;
    border-bottom: 1px solid $input-border-color;

    .search-wrapper {
        height: 29px;
        flex-basis: 100%;
        position: relative;

        input {
            border: 1px solid #D1D1D1;
            width: 100%;
            height: 100%;
            padding-left: 30px;

            &::placeholder {
                color: #979797;
            }
        }

        button {
            position: absolute;

            &:first-of-type {
                left: 5px;
                top: 3.4px;
                background: none;
                border: none;
                color: #C1C1C1;
                padding: 6px 6px 5px;
            }

            &:last-of-type {
                font-size: 0.857rem;
                padding: 2px 5px;
                right: 5px;
                top: 3.4px;
                display: none;
            }
        }
    }

    .btn.btn-sm.close {
        padding: 5px;
        color: $lightblue;
        font-size: 1rem;
        margin-left: 10px;
    }

    &.hidden {
        display: none;
    }
}

.search-results-wrapper {
    display: none;
    padding: 15px;
    max-height: 360px;
    overflow-y: scroll;
    border-bottom: $input-border;

    h2 {
        font-size: 1.285rem;
        font-weight: $font-weight-bold;
        padding-left: 7.5px;

        strong {
            font-weight: inherit !important;
        }
    }

    .result_set {
        display: flex;
        padding: 7.5px 10px;

        > div {
            flex-basis: calc(100% / 3);
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $tertiarygrey;
        }
    }

    .search-results-header-inner {
        color: #808080;
    }
}

.search-results-header,
.search-no-results-text {
    display: none;
}

@include media-breakpoint-down(lg) {
    .search-container {
        .btn.btn-sm.close {
            padding: 0;
            font-size: 1.5rem;
            margin: 0 5px 0 10px;

            span {
                display: none;
            }
        }
    }
}