.users {
    &:not(.controlpanel) {
        .content {
            display: flex;
            gap: 20px;

            .left-column {
                max-width: 420px;

                .section_block {
                    text-align: center;

                    .nav-pills {
                        .btn {
                            background-color: $white;
                            border-color: $white;
                            color: $primary-button-background;

                            &.btn-primary:active,
                            &.btn-primary.active {
                                color: $primary-button-color;
                                background-color: $primary-button-background;
                                border: $primary-button-border;
                            }
                        }
                    }

                    form {
                        margin-top: 15px;

                        .btn {
                            margin: 0 auto;
                            margin-top: 10px;
                        }
                    }
                }
            }

            .right-column {
                flex: 1;

                .tab-content {
                    padding: 0;
                    margin: 0;
                    border-bottom: 0;

                    h2 {
                        margin-bottom: 15px;
                    }

                    .form-group:last-of-type {
                        margin-bottom: 0;
                    }

                    .comment {
                        display: flex;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #dee2e6;
                        margin-bottom: 10px;

                        &:last-of-type {
                            border-bottom: 0;
                            margin-bottom: 0;
                            padding-bottom: 0;
                        }

                        .avatar {
                            width: 47px;
                        }
                    }
                }
            }

            .section_block {
                padding: 15px;
                border: 1px solid #DDDDDD;
                margin-bottom: 20px;
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    .users {
        &:not(.controlpanel) {
            .content {
                flex-direction: column;
                gap: 0;

                .left-column {
                    max-width: 100%;
                }
            }
        }
    }
}