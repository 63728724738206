.access_denied,
.lockout {
    .content {
        display: flex;
        justify-content: center;

        > div {
            text-align: center;
        }
    }
}