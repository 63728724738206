body.controlpanel.boards {
    .content-left-column {
        > label {
            display: block;
            margin-bottom: 5px;
        }
    }

    .content-right-column {
        padding-top: 20px;
    }

    #properties_buffer {
        .row {
            gap: 0;
        }

        .properties-header {
            font-size: 1rem;
            font-weight: 600;
            color: #808080;
            border-bottom:1px solid #D5D5D5;
            padding-bottom: 10px;

            display: grid;
            grid-template-columns: repeat(9, 1fr);
            gap: 10px;
        }

        .form-group {
            display: none;
            grid-template-columns: repeat(9, 1fr);
            gap: 10px;
        }

        .mobile-title {
            display: none;
        }

        .fa-times {
            cursor: pointer;
        }

        .repr_selectors {
            width: auto;
        }
    }

    .visible_for_fields,
    .editable_by_fields {
        .options_holder_usergroups,
        .options_holder_users {
            margin-top: 5px;
        }
    }

    .by_fields_padding {
        padding: 10px;
    }

    #shrink_board_properties_screen {
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    #properties_buffer {
        overflow-x: scroll;
    }

    body.controlpanel.boards {
        #properties_buffer {
            .mobile-title {
                display: inline-block;
            }

            .properties-header {
                display: none;
            }

            .form-group {
                grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
                border-bottom: 1px solid #dddddd;
                padding-bottom: 20px;
                align-items: start;
            }
        }
    }
}

@include media-breakpoint-down(sm) {
    body.controlpanel.boards {
        .two-column {
            .right-column {
                .tab-content {
                    .tab-pane-content {
                        .content-columns {
                            flex-direction: column;
                            gap: 0;
                        }
                    }
                }
            }

            .content-right-column {
                padding-top: 0;
            }
        }
    }
}