.form-group,
.form-check,
.row {
    margin-bottom: 25px;
    padding: 0;
    display: flex;
    gap: 7.5px;

    .image,
    > .controls {
        display: inline-block;

        .btn {
            margin-top: 5px;

            &.btn-inline {
                margin-top: 0;
            }

            &.btn-form-inline {
                padding: 8px 8px;
            }
        }

        img.thumbnail {
            display: block;
            margin-bottom: 5px;
        }

        table {
            &.grid {
                td {
                    input[type="checkbox"] {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;

                        &:before {
                            height: inherit;
                            width: inherit;
                            vertical-align: middle;
                            text-align: center;
                            font-size: 2rem;
                            margin-top: 0;
                        }
                    }

                    &.hover {
                        input[type="checkbox"] {
                            background: $input-checked-background;

                            &:before {
                                font-family: $font-font-awesome;
                                content: '\f00c';
                                color: $input-checked-color;
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }

    .input-append {
        display: inline-block;
        position: relative;

        .add-on {
            position: absolute;
            right: 1px;
            top: 1px;
            width: 30px;
            height: 32px;
            line-height: 32px;
            background: #eee;
            text-align: center;
            border-left: $input-border;

            i {
                margin-top: calc(50% - 7px);
            }
        }

        .form-control.form-control-sm {
            width: 75px;
        }
    }

    &.or {
        margin: -18.75px 0 6.25px;
    }

    .controls {
        display: inline-block;

        @include media-breakpoint-up(md) {
            .dropzone {
                width: 506px;
            }

            small {
                max-width: 506px;
            }
        }
    }

    .btn-group-vertical {
        input + label {
            display: inline-block;
            margin: 0;
        }

        > div:not(:last-of-type) {
            margin-bottom: 5px;
        }
    }

    small {
        max-width: 253px;
    }

    &.search {
        position: relative;

        &:before {
            font-family: $font-font-awesome;
            content: '\f002';
            color: $input-border-color;
            font-size: 1rem;
            display: block;
            position: absolute;
            top: 8px;
            left: 8px;
        }

        input {
            padding-left: 30px;
        }
    }

    label {
        font-size: $font-size-base;
        font-weight: $font-weight-semibold;
        width: 200px;
        min-width: 200px;
        vertical-align: top;

        & + label, &.checkbox {
            font-weight: $font-weight-base;
            width: auto;
        }

        &.sub-label {
            font-weight: $font-weight-normal;
        }
    }

    .input-group {
        display: inline-block;
        width: auto;

        > .form-control {
            display: inline-block;
            width: 253px;
        }

        .btn {
            padding: 8px 8px;
            vertical-align: top;
        }
    }

    .icons-selector {
        height: 34px !important;

        .selector {
            height: 34px !important;

            > span {
                border-radius: 0 !important;
                box-shadow: none !important;

                &.selected-icon i {
                    line-height: 34px;
                }

                &.selector-button,
                &.selector-button:hover {
                    background: none;
                    border-left: none;

                    i {
                        font-size: 14px;
                        line-height: 34px;
                        color: $black;
                    }
                }
            }
        }
    }

    .form-check-input {
        float: none;
        margin: 2px 0 0;

        & + label {
            font-weight: $font-weight-normal;
            margin-top: 0;
        }
    }

    small {
        display: block;
    }

    .redactor-box {
        display: inline-block;
        max-width: 800px;
    }

    .span-220 {
        width: 220px;
        display: inline-block;
    }
}

.select2-container {
    vertical-align: top;
    padding: 0;

    .select2-choice {
        background: none !important;
        border: none;
        padding: 0;
        border-radius: 0;
        color: $black;
        box-shadow: none;
        line-height: 32px;
        height: 32px;

        .select2-chosen {
            padding: 0 0.75rem;
        }

        .select2-arrow {
            border: none;
            background: none;
            top: 2px;
        }
    }

    &.select2-container-disabled {
        .select2-choice {
            border: none;
            border-radius: 0;
            cursor: not-allowed;
        }
    }

    &.select2-container--default {
        .select2-selection--single {
            border: $input-border;
            border-radius: 0;
            display: inline-block;
            width: 100%;
            height: 34px;
            line-height: 34px;
            padding: 0 0.75rem;

            > span {
                height: inherit !important;
                line-height: inherit !important;
                padding: 0 !important;
            }

            .select2-selection__arrow {
                right: 7px;

                b {
                    border-color: $black transparent transparent transparent;
                }
            }
        }

        .select2-selection--multiple {
            border: $input-border;
            border-radius: 0;
            display: inline-block;
            width: 100%;
        }

        &.select2-container-disabled {
            background: #e9ecef;
            cursor: not-allowed;
            pointer-events: auto;

            a {
                cursor: inherit;
                pointer-events: inherit;
            }
        }
    }
}

.form-check {
    align-items: start;
}

.form-group {
    &:has(.d-inline-block) {
        align-items: start;

        > label {
            padding-top: 8.5px;
        }
    }

    .file-container {
        padding: 10px 30px;
        border: 1px solid #D0D0D0;
        display: flex;
        flex-direction: row;
        gap: 30px;
        justify-content: space-between;
        align-items: center;
        min-width: 500px;

        .file-preview {
            .fa-file-alt {
                font-size: 75px;
                color: #357CE3;
            }
        }

        .file-info {
            display: flex;
            flex-direction: column;
            font-size: 12px;

            .file-title {
                font-weight: bold;
            }
        }
    }

    .d-flex {
        gap: 7.5px;

        > *:not(a.create_another_one) {
            align-self: flex-start;
        }
    }
}

.form-actions {
    background: $lightgrey;
    padding: 0 15px 15px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;

    > a.btn,
    > button,
    > .dropdown,
    > .btn-group,
    > div:not(.dropdown):not(.btn-group) > .btn {
        margin-right: 15px;
        margin-top: 15px;
    }

    > *:last-child {
        margin-right: 0 !important;
    }

    .btn-group {
        display: inline-flex;
    }
}

input {
    &.form-check-input {
        float: none !important;
        margin: 2px 0 0;

        &[type="checkbox"] {
            border-radius: 0;
            width: 14px;
            height: 14px;

            &:checked {
                background: $input-checked-background;

                &:before {
                    font-family: $font-font-awesome;
                    content: '\f00c';
                    color: $input-checked-color;
                    font-size: 0.9rem;
                    display: block;
                    margin-top: -2px;
                }
            }
        }
    }
}

.form-control {
    border: $input-border;
    display: inline-block;
    width: 253px;
    border-radius: 0;

    @include media-breakpoint-up(md) {
        &.large {
            width: 506px;
        }
    }

    &.form-control-sm {
        width: 45px;
    }

    &.form-control-md {
        width: 90px;
    }

    &:not(textarea):not(select) {
        height: 34px;
    }

    &.box.draggable {
        border: none;
        padding: 0;
        height: auto;

        .draggable-element {
            display: block;
            color: $white;
            padding: 0 15px;
            line-height: 38px;
            background-color: $blue;
            cursor: pointer;
            opacity: 0.4;

            i {
                margin-right: 5px;
            }

            &:not(:last-of-type) {
                margin-bottom: 5px;
            }

            &.current {
                opacity: 1;
            }
        }
    }

    &.select2-container-multi {
        padding: 0;
        border: none;

        .select2-choices {
            margin-left: -1px;
            margin-top: -1px;
        }
    }

    & + .btn,
    & + .btn + .btn {
        padding: 8px 8px;
        vertical-align: top;
        margin-top: 0;
    }
}

select.form-control {
    appearance: none;
    background: $white url('/public/lib/select2/select2.png') no-repeat 230px 3px;
    padding: 0.375rem 1.2rem 0.375rem 0.75rem;
}

input[disabled],
select[disabled],
textarea[disabled],
input[readonly],
select[readonly],
textarea[readonly],
.select2-container--disabled *,
.form-check-input:disabled {
    cursor: not-allowed;
    pointer-events: auto;
}

.form-select-sm {
    border-radius: 0;
}

.has-error {
    color: red !important;
}

.select2-container {
    &.select2-container--open {
        .select2-dropdown--below {
            top: -5px;
        }
    }
}

.select2-drop-active {
    border-radius: 0;
    border-color: $input-border-color;

    .select2-results {
        .select2-highlighted {
            background: $lightblue;
        }
    }
}

@include media-breakpoint-down(lg) {
    .form-group {
        flex-direction: column;
        align-items: initial;
    }
}

@include media-breakpoint-down(md) {
    .form-group-mobile {
        display: flex;
    }

    .form-group-mobile table {
        max-width: 100%;
        display: inline;
    }

    .form-group {
        .file-container {
            flex-direction: column;
            min-width: auto;
        }
    }
}

@include media-breakpoint-down(sm) {
    .form-actions {
        margin-bottom: 8px;
    }
}