html {
    height: 100%;
}

body {
    height: calc(100% - 81px);
}

.main {
    display: flex;
    width: 100vw;
    height: 100%;
    flex-direction: column;

    .content-container {
        height: 100%;
        width: 100%;
        border-left: 1px solid #d5d5d5;
        margin-left: -1px;
    }
}

.layout-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
}

.row {
    --bs-gutter-x: 0;
}

.two-columns {
    display: flex;
    gap: 20px;

    > div {
        flex-basis: 50%;

        &.full-width {
            flex-basis: 100%;
        }
    }
}

@include media-breakpoint-up(sm) {
    body {
        height: calc(100% - 51px);
    }

    .main {
        flex-direction: row;
    }

    body {
        &.menu-in {
            .content-container {
                width: calc(100% - 42px); // Navigation width when nav is collapsed
            }
        }

        &.menu-out {
            .content-container {
                width: calc(100% - 225px); // Navigation width when nav is not collapsed
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .two-columns {
        gap: 0;
        flex-direction: column;

        > div {
            flex-basis: 100%;
        }
    }
}