.products.details {
    .form-actions {
        #shrink_detail_screen {
            display: none;
        }
    }

    #textbox_holder {
        display: none;
    }

    #linked_products {
        z-index: 9000;
        position: absolute;
        top: 20%;
        left: 50%;
        min-height: 400px;
        background: white;
        border: 5px solid #fff;
        border-radius: 5px;
        display: none;
        box-shadow: 10px 10px 5px #444;
    }

    .two-columns {
        > div {
            &:first-of-type {
                max-width: 520px;
            }

            &:last-of-type {
                flex-basis: 100%;
            }

            > div {
                border: 1px solid #DDDDDD;
                padding: 30px;
                margin-bottom: 20px;

                table {
                    margin-bottom: 0;
                    font-size: 1rem;
                }

                .tabbable {
                    .tab-content {
                        margin-bottom: 0;
                    }
                }

                .attribute {
                    label {
                        flex-basis: 15%;
                    }

                    .property-value {
                        flex-basis: 85%;

                        .form-control,
                        .tabbable.language,
                        > div > .cke_reset {
                            width: calc(100% - 30px);
                        }

                        .tabbable.language {
                            .form-control {
                                width: 100%;
                            }
                        }

                        .revision-badge {
                            background: #757575;
                            border-radius: 70px;
                            color: $white;
                            font-weight: normal;
                            right: 0;
                            top: 8px;
                            position: absolute;
                        }

                        .multiple-resource {
                            .multiple-resource-search-results {
                                width: calc(100% - 30px);
                                min-height: 15px;
                                max-height: 200px;
                                overflow-y: scroll;
                                border: $input-border;
                                border-top: none;
                                padding: 0 0 0 4px;

                                .multiple-resource-header-result {
                                    padding: 3px 7px 4px;
                                    font-weight: $font-weight-semibold;
                                }

                                .multiple-resource-selectable-result {
                                    cursor: pointer;
                                    padding: 3px 7px 4px;

                                    &:hover {
                                        background: #3875d7;
                                        color: $white;
                                    }
                                }
                            }

                            .multiple-resource-selected {
                                display: flex;
                                flex-direction: column;
                                gap: 5px;
                                width: calc(100% - 30px);
                                padding: 5px 0;

                                .fa-times {
                                    cursor: pointer;
                                }

                                > div {
                                    @extend .badge;
                                    font-weight: normal;
                                    background: $lightblue;
                                    color: $white;
                                    cursor: pointer;
                                    text-align: left;
                                }
                            }
                        }
                    }
                }

                .comments_box {
                    font-size: 0.857rem;

                    .comments_info,
                    .commenttags_filter {
                        margin-bottom: 15px;
                        color: #898989;

                        .filters {
                            display: flex;
                            gap: 5px;

                            > div {
                                @extend .badge;
                                font-weight: normal;
                                background: $lightblue;
                                color: $white;
                                cursor: pointer;
                                font-size: 0.857rem;

                                &.commenttag_highlight {
                                    background: #0099D8;
                                }
                            }
                        }
                    }

                    > .comment {
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid #DDDDDD;

                        .content-wrapper {
                            display: flex;
                            flex-direction: row;

                            .avatar {
                                flex-basis: 10%;
                            }

                            .comment_info {
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                flex-basis: 90%;
                                gap: 5px;

                                .comment_message {
                                    flex-basis: 100%;
                                }

                                .comment_user_name,
                                .comment_date {
                                    align-self: flex-end;
                                }

                                .cursor {
                                    position: absolute;
                                    top: 0;
                                    right: 0;
                                    cursor: pointer;
                                }
                            }
                        }
                    }

                    #reply_form {
                        label {
                            font-size: inherit;
                        }
                    }
                }
            }
        }
    }

    .bundle {
        position: relative;

        &:after {
            position: absolute;
            right: 30px;
            top: 10px;
            content: "\f13a";
            font-family: $font-font-awesome;
            font-size: 14px;
        }

        &.bundle-active {
            &:after {
                content: "\f139";
            }
        }
    }

    #linkedProductsModal {
        .linked_products_data {
            ul {
                @extend .list-unstyled;
            }
        }
    }
}

@include media-breakpoint-down(lg) {
    .products.details {
        .two-columns {
            > div {
                &:first-of-type {
                    max-width: 100%;
                }

                > div {
                    .attribute {
                        flex-direction: column;

                        label,
                        .property-value {
                            flex-basis: 100%;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.img_container {
    position: relative;

    .zoom-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 30px;
        height: 30px;
        background-color: #fff;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 9999;

        i {
            font-size: 2rem;
        }
    }
}

#zoom_popover_template {
    display: none;
}

#zoom_popover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    background-color: #fff;

    .zoom_image {
        img {
            cursor: url(/public/themes/mars/img/plus.cur), url(/public/themes/mars/img/plus.png), zoom-in !important;

            &.max_zoom_level {
                cursor: url(/public/themes/mars/img/min.cur), url(/public/themes/mars/img/min.png), zoom-out !important;
            }
        }
    }
}

.zoom_image {
    height: 100%;
    overflow: hidden;
}

.bar {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 9999;
}

.close_popover,
.zoom-btn {
    width: 100%;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 24px;
    margin-bottom: 5px;
    background-color: #f2f2f2;

    &:hover {
        background-color: #e7e7e7;
    }

    @media (max-width: 1024px) {
        padding: 10px;
        font-size: 18px;
    }
}

.main_img {
    cursor: pointer;
}