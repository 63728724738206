.reports.show {
    .edit_field,
    .field_edit {
        display: none;
    }

    .showProductComments {
        cursor: pointer;
    }

    .toolbar {
        margin-bottom: 15px;
    }
}