nav {
    > ul {
        li {
            &.menu-node {
                &.menu-segment {
                    > a {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    &.menu-left {
        position: relative;

        &.menu-in {
            &.animate {
                animation: menu-in-mobile 100ms ease-out;
                animation-fill-mode: both;
            }

            &.static {
                animation: menu-in-mobile 0ms ease-out;
                animation-fill-mode: both;
            }
        }

        &.menu-out {
            &.animate {
                animation: menu-out-mobile 100ms ease-in;
                animation-fill-mode: both;
            }

            &.static {
                animation: menu-out-mobile 0ms ease-in;
                animation-fill-mode: both;
            }

            ul {
                li {
                    &.menu-segment {
                        .title {
                            flex-grow: 1;
                            display: inline-block;
                        }
                    }
                }
            }

            @include media-breakpoint-up(sm) {
                min-width: 225px;
            }
        }

        ul {
            border-right: 1px solid $tertiarygrey;
            list-style: none;
            margin: 0;
            padding: 0;
            background-color: #fff;

            li {
                &.menu-segment {
                    word-break: break-word;

                    > a {
                        display: flex;
                        align-items: center;
                        padding: 9px 10px;
                        color: #03060C;
                        font-weight: $font-weight-semibold;
                        gap: 10px;

                        .icon {
                            width: 1.5rem;
                            height: 1.5rem;
                            background-position: center;
                            background-size: contain;
                        }

                        .title {
                            flex-grow: 1;
                            display: none;
                        }
                    }

                    &.active,
                    &:hover {
                        > a {
                            background: $lightgrey;
                        }
                    }

                    &:not(:first-of-type) {
                        border-top: 1px solid $tertiarygrey;
                    }

                    &:last-of-type {
                        border-bottom: 1px solid $tertiarygrey;
                    }

                    &.has-children {
                        a {
                            &:after {
                                font-family: $font-font-awesome;
                                content:'\f0d7';
                                color: $darkgrey;
                            }
                        }

                        &.active,
                        &:hover {
                            > a {
                                &:after {
                                    content:'\f0d8';
                                }
                            }
                        }
                    }
                }

                ul {
                    border-right: none;
                    display: none;

                    > li {
                        a {
                            padding: 10px 17.5px;

                            &.active {
                                color: #03060C;
                            }
                        }
                    }
                }

                a {
                    display: block;
                }
            }
        }

        .search-box {
            padding: 2.5px 5px;
            background: $lightgrey;
            height: 33px;
            border-bottom: 1px solid $tertiarygrey;
            border-right: 1px solid $tertiarygrey;

            .form-control {
                width: 100%;
                height: 26px;
            }
        }
    }

    &.segment-blocks {
        > ul {
            @extend .list-unstyled;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
            gap: 15px;

            li.menu-node {
                border: 1px solid $tertiarygrey;
                border-radius: 5px;

                a {
                    min-height: 160px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 15px;
                    color: #03060C;
                    font-weight: $font-weight-semibold;

                    .title {
                        font-size: 1.142rem;
                        text-align: center;
                        word-break: break-word;
                        padding: 0 5px;
                    }
                }

                &:hover {
                    a {
                        background: $lightgrey;
                    }
                }
            }
        }
    }
}

.wizard-steps {
    display: flex;
    width: 100%;
    justify-content: space-between;

    li {
        display: flex;
        border-radius: 4px;
        width: 9rem;
        height: 5rem;
        border: 3px solid transparent;
        background-color: $tertiarygrey;
        justify-content: center;
        align-items: center;
        text-align: center;

        &.active {
            border-color: $info;
        }
    }
}

@include media-breakpoint-up(sm) {
    .wizard-steps {
        li {
            width: 10rem;
            height: 10rem;
            border-radius: 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    nav.menu-left {
        ul {
            li {
                &.menu-segment  {
                    > a {
                        .title {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        &.menu-in {
            display: none;
        }
    }
}