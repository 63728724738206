.login,
.password_forgotten {
    .content {
        display: flex;
        justify-content: center;

        .login-container {
            width: 324px;
            margin-top: 50px;

            .alert {
                margin: 0 0 20px;
            }

            form {
                border: 1px solid $tertiarygrey;
            }

            .login-content {
                padding: 20px;
            }

            .form-actions {
                margin-bottom: 0;
            }

            h1 {
                margin: 0;
                margin-bottom: 20px;
            }

            .form-control {
                width: 100%;
            }

            form .form-group, form .form-check {
                margin-bottom: 10px;

                &:last-of-type {
                    margin-bottom: 0;
                }

                label {
                    margin-bottom: 5px;
                }
            }

            .form-group, .form-check {
                display: inherit;

                label {
                    width: 100%;
                }
            }

            .form-actions {
                display: inherit;
                padding: 15px;

                .btn {
                    margin-top: 0;
                }

                a {
                    margin-top: 15px;
                    display: inline-block;
                }
            }
        }
    }
}
