.toolbar {
    background: #f5f5f5;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    justify-content: space-between;

    > div {
        display: flex;
        align-items: center;
        gap: 20px;
    }

    .search-container {
        order: 1;
        background: none;
        border: none;
        padding: 0;
        position: relative;

        input {
            padding: 0.375rem 0.75rem 0.375rem 30px;
        }

        &:before {
            position: absolute;
            left: 10px;
            top: 8.5px;
            content: '\f002';
            font-family: $font-font-awesome;
            color: #C1C1C1;
        }
    }

    .tools-container {
        order: 2;

        > div {
            display: inherit;
            align-items: inherit;
            gap: 10px;
        }

        .pagination {
            order: 2;

            > .form-control {
                width: auto;
                max-width: 50px;
                text-align: center;
            }
        }

        .limiter {
            order: 3;

            > .form-control {
                width: auto;
            }
        }

        .display-type {
            order: 4;

            > i {
                cursor: pointer;
                color: $blue;
                font-size: 1.428rem;

                &.active {
                    color: $lightblue;
                }
            }
        }
    }
}

@include media-breakpoint-down(md) {
    .toolbar {
        flex-direction: column;
        align-items: initial;

        > div {
            flex-basis: 100%;
            justify-content: space-between;

            > input {
                width: 100%;
            }
        }
    }
}