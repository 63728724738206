.tabbable {
    .tab-content {
        padding-top: 25px;
    }

    .nav-tabs {
        .nav-item {
            &:hover {
                border: none;
            }

            .nav-link {
                border-radius: 0;
                transition: none;
                color: $lightblue;

                &.active {
                    color: $black;
                    font-weight: $font-weight-semibold;
                }

                &:not(&.active):hover {
                    text-decoration: underline;
                    border: none;
                    padding: 0.571rem 1.071rem;
                }
            }
        }
    }

    &.language {
        .tab-content {
            padding-top: 0;
            border-bottom: none;
        }
    }
}

.nav-tabs,
.nav-item,
.nav-link {
    border-radius: 0 !important;
    color: $blue;
}

.tab-content {
    padding-top: 25px;
    border-bottom: 1px solid #dee2e6;
    margin-bottom: 25px;;
}
