.table {
    width: 100%;
    font-size: 0.857rem;
    border-color: $table-border-color;

    tr {
        &:first-child {
            th {
                color: #808080;

                a {
                    color: #808080;
                }
            }
        }

        th {
            font-weight: $font-weight-semibold;

            > i:not(.fa-sort) {
                color: $red;
                font-size: 1.2rem;
            }
        }

        td {
            padding: 0.75rem 0.5rem;

            > a {
                i {
                    font-size: 1.2rem;
                }
            }

            .showLess {
                display: none;
            }

            .status_activeness {
                border-radius: 2px;
                padding: 4px 7px;
                color: $white;

                &.label-success {
                    background-color: $success;
                }

                &.label-warning {
                    background-color: $warning;
                }

                &.label-danger {
                    background-color: $danger;
                }

                &.label-info {
                    background-color: $info;
                }
            }
        }
    }

    &.clickable-rows {
        tbody {
            tr[onclick] {
                &:hover,
                &.active {
                    background: #F3F3F3;
                    cursor: pointer;
                }
            }
        }
    }
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: $white;
}

.table-striped > tbody > tr:nth-of-type(even) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);;
}

.table-top, .table-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    .pagination {
        margin-bottom: 0;

        li {
            a {
                padding: 5px;
                border: 1px solid $lightblue;
                border-left: 0;
                min-width: 30px;
                min-height: 30px;
                display: inline-block;
                text-align: center;
            }
        }

        li:first-child {
            a {
                border-left: 1px solid $lightblue;
            }
        }

        li.active {
            a {
                background-color: $lightblue;
                color: $white;
            }
        }

        li.disabled {
            a {
                color: $darkgrey;
            }
        }
    }
}

.table-bottom {
    margin-top: 15px;
}

#cp_default_wrapper {
    form {
        overflow-x: scroll;
    }
}

@include media-breakpoint-down(sm) {
    .table-top, .table-bottom {
        flex-direction: column;

        div:first-child {
            margin-bottom: 8px;
        }

        .table-search {
            width: 100%;

            .form-control {
                width: 100%;
            }
        }
    }
}