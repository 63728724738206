.breadcrumb-container {
    border-bottom: $input-border;
    min-height: 40px;
    display: flex;
    align-items: center;

    .breadcrumb {
        margin: 0;
        padding: 10px 15px;
        gap: 5px;

        li {
            &:not(:last-of-type) {
                &:after {
                    content: ' / ';
                    color: #D4D4D4;
                }
            }
        }
    }
}