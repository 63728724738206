.boards,
.products,
.models,
.reports.showboards,
body.filter {
    .item_name_small {
        margin-bottom: 15px;
    }

    .posters {
        display: grid;
        gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
        grid-auto-rows: 1fr;

        .poster-container {
            min-height: 302px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 3px #00000029;
            border: 1px solid #DDDDDD;

            .poster-inner {
                padding: 10px;

                .poster-image {
                    height: 174px;
                    text-align: center;
                    margin-bottom: 10px;

                    .img-fluid {
                        max-height: 100%;
                    }
                }

                .top_info {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    margin-bottom: 10px;

                    .right_pane {
                        flex: 1;

                        a {
                            font-size: 1.142rem;
                            font-weight: bold;
                            word-break: break-word;
                        }

                        .blk_date {
                            font-size: 0.857rem;
                            color: $darkgrey;
                            margin-bottom: 0;
                        }
                    }
                }

                .sub_info {
                    font-size: 0.857rem;
                    margin-bottom: 10px;
                }

                .description {
                    font-size: 0.857rem;
                }

                .meta {
                    i {
                        cursor: pointer;
                        color: lightgrey;

                        &.selected {
                            color: $lightblue;
                        }
                    }
                }
            }
        }

        & + .form-actions {
            margin-top: 20px;
        }
    }

    #load_more {
        display: none;
        margin: 0 auto;
        width: 60px;
    }
}